import React from 'react';
export default function campaign() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.129"
      height="21.091"
      viewBox="0 0 14.129 16.091"
    >
      <g transform="translate(-292.51 -103)">
        <g transform="translate(292.51 103)">
          <path
            d="M20.672,961.761v4.175c-1.881,2.4-3.712,3.13-5.85,4.1a1.245,1.245,0,0,0-.822,1.14,1.176,1.176,0,0,0,.779,1.1,8.79,8.79,0,0,1-.748,3.74.409.409,0,0,0,.264.527,25.6,25.6,0,0,0,13.54,0,.409.409,0,0,0,.264-.527,8.79,8.79,0,0,1-.748-3.74,1.176,1.176,0,0,0,.779-1.1,1.292,1.292,0,0,0-.8-1.134c-1.963-.981-3.99-1.718-5.869-4.108v-1.012c.376.044.74.137,1.171.2a4.148,4.148,0,0,0,2.1-.245.4.4,0,0,0,.258-.368v-2.354a.412.412,0,0,0-.515-.368,8.754,8.754,0,0,1-3.017-.025.408.408,0,0,0-.4-.4.434.434,0,0,0-.386.4Zm-5.494,9.024a59.367,59.367,0,0,0,11.774,0,.383.383,0,0,1,.392.392.393.393,0,0,1-.429.4,58.684,58.684,0,0,1-11.7,0,.429.429,0,0,1-.429-.4.394.394,0,0,1,.392-.392Zm.386,1.606a59.435,59.435,0,0,0,6.04.251,6.7,6.7,0,0,1-2.349,3.917l.025.037a25.9,25.9,0,0,1-4.366-.711A9.787,9.787,0,0,0,15.564,972.391Zm11,0a9.787,9.787,0,0,0,.65,3.495,25.942,25.942,0,0,1-4.244.7,3.367,3.367,0,0,1-1.11-2.109,6.313,6.313,0,0,0,.546-1.845Q24.486,972.583,26.565,972.391Z"
            transform="translate(-14 -961.362)"
            fill="#95a1b1"
          />
        </g>
      </g>
    </svg>
  );
}
