import React from 'react';
export default function offers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.679"
      height="18.679"
      viewBox="0 0 13.679 13.679"
    >
      <g transform="translate(0 0)">
        <path
          d="M-2770.8-17.682a1.313,1.313,0,0,1-.958-.41l-4.788-4.788a1.311,1.311,0,0,1-.41-.958,1.369,1.369,0,0,1,.41-.974l6.156-6.156a1.361,1.361,0,0,1,.958-.393h4.788a1.369,1.369,0,0,1,1.367,1.368v4.788a1.368,1.368,0,0,1-.41.974l-6.155,6.156A1.362,1.362,0,0,1-2770.8-17.682Zm3.885-12.041a2,2,0,0,0-2,2,2,2,0,0,0,2,2,2,2,0,0,0,2-2A2,2,0,0,0-2766.918-29.723Z"
          transform="translate(2776.959 31.362)"
          fill="#d8e0eb"
        />
      </g>
    </svg>
  );
}
