import * as React from 'react';

export default function survey() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.083"
      height="17"
      viewBox="0 0 17.083 17"
    >
      <g transform="translate(0 0)" opacity="0.6">
        <g transform="translate(0)">
          <path
            d="M-2762.942,1771.685a3.847,3.847,0,0,1-3.842-3.843,3.846,3.846,0,0,1,3.842-3.842,3.847,3.847,0,0,1,3.843,3.842A3.847,3.847,0,0,1-2762.942,1771.685Zm-1.426-4.4a.535.535,0,0,0-.392.167.538.538,0,0,0-.17.4.536.536,0,0,0,.168.394l.948.949a.533.533,0,0,0,.4.171.526.526,0,0,0,.394-.168l1.9-1.9a.538.538,0,0,0,.17-.4.55.55,0,0,0-.168-.4.541.541,0,0,0-.394-.167.537.537,0,0,0-.393.167l-1.094,1.084-.415.416-.555-.546A.537.537,0,0,0-2764.367,1767.281Z"
            transform="translate(2766.784 -1764)"
            fill="#fff"
          />
        </g>
        <g transform="translate(9.398 9.315)">
          <path
            d="M-2772.339,1762.37a3.847,3.847,0,0,1-3.842-3.843,3.846,3.846,0,0,1,3.842-3.842,3.847,3.847,0,0,1,3.843,3.842A3.847,3.847,0,0,1-2772.339,1762.37Zm-1.427-4.4a.535.535,0,0,0-.392.167.542.542,0,0,0-.17.4.539.539,0,0,0,.168.394l.949.948a.53.53,0,0,0,.4.171.525.525,0,0,0,.393-.167l1.9-1.9a.542.542,0,0,0,.17-.4.542.542,0,0,0-.168-.394.54.54,0,0,0-.393-.168.536.536,0,0,0-.394.168l-1.094,1.085-.415.415-.555-.546A.537.537,0,0,0-2773.766,1757.966Z"
            transform="translate(2776.182 -1754.685)"
            fill="#fff"
          />
        </g>
        <g transform="translate(9.398)">
          <path
            d="M-2772.339,1771.685a3.847,3.847,0,0,1-3.842-3.843,3.846,3.846,0,0,1,3.842-3.842,3.847,3.847,0,0,1,3.843,3.842A3.847,3.847,0,0,1-2772.339,1771.685Zm0-3.02h0l.752.74a.552.552,0,0,0,.405.174.552.552,0,0,0,.405-.174.554.554,0,0,0,.174-.405.554.554,0,0,0-.174-.406l-.741-.752.741-.752a.552.552,0,0,0,.174-.4.552.552,0,0,0-.174-.405.552.552,0,0,0-.405-.174.552.552,0,0,0-.405.174l-.752.741-.752-.741a.552.552,0,0,0-.405-.174.552.552,0,0,0-.405.174.552.552,0,0,0-.174.405.552.552,0,0,0,.174.4l.741.752-.741.752a.554.554,0,0,0-.174.406.551.551,0,0,0,.174.4.553.553,0,0,0,.405.173.553.553,0,0,0,.405-.173l.752-.74Z"
            transform="translate(2776.182 -1764)"
            fill="#fff"
          />
        </g>
        <g transform="translate(0 9.315)">
          <path
            d="M-2762.942,1762.37a3.847,3.847,0,0,1-3.842-3.843,3.846,3.846,0,0,1,3.842-3.842,3.847,3.847,0,0,1,3.843,3.842A3.847,3.847,0,0,1-2762.942,1762.37Zm0-3.02h0l.752.74a.559.559,0,0,0,.406.174.557.557,0,0,0,.405-.174.553.553,0,0,0,.173-.405.553.553,0,0,0-.173-.405l-.742-.752.742-.753a.553.553,0,0,0,.173-.405.553.553,0,0,0-.173-.405.556.556,0,0,0-.406-.173.556.556,0,0,0-.406.173l-.752.741-.752-.741a.553.553,0,0,0-.405-.173.553.553,0,0,0-.405.173.554.554,0,0,0-.174.406.553.553,0,0,0,.174.4l.741.753-.741.752a.552.552,0,0,0-.174.405.552.552,0,0,0,.174.405.553.553,0,0,0,.405.173.553.553,0,0,0,.405-.173l.751-.74Z"
            transform="translate(2766.784 -1754.685)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
