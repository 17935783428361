import * as React from 'react';

export default function segemnts() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.638"
      height="12.102"
      viewBox="0 0 13.638 12.102"
    >
      <g transform="translate(-69 -298.294)">
        <path
          d="M60.97,35.178a.808.808,0,0,0-.589-.314.818.818,0,0,0-.626.231h0L57.035,37.82a.811.811,0,0,0,0,1.143L59.7,41.624a.818.818,0,0,0,.573.231h.042a.818.818,0,0,0,.589-.3,5.176,5.176,0,0,0,1.141-3.234,5.1,5.1,0,0,0-1.069-3.144Zm-.61,5.952a.106.106,0,0,1-.085.044.113.113,0,0,1-.09-.035l-2.647-2.665a.115.115,0,0,1-.035-.081.122.122,0,0,1,.032-.083l2.709-2.723a.111.111,0,0,1,.092-.035.118.118,0,0,1,.085.044,4.462,4.462,0,0,1-.062,5.534Z"
          transform="translate(20.599 265.984)"
          fill="#fff"
          opacity="0.6"
        />
        <path
          d="M27.283,29.986a.115.115,0,0,1,0-.164l3.282-3.282a.8.8,0,0,0,.231-.594.825.825,0,0,0-.275-.589,5.991,5.991,0,0,0-4.726-1.5,6.049,6.049,0,1,0,4.686,10.551.811.811,0,0,0,.039-1.185Z"
          transform="translate(48.558 274.479)"
          fill="#fff"
          opacity="0.6"
        />
      </g>
    </svg>
  );
}
